<template>
  <div class="flex border border-light">
    <button
      class="w-32 h-32 desk:w-40 desk:h-40 flex items-center justify-center group"
      :class="{
        touchOnly: !showButtonsOnDesktop,
        disabled: loading,
        'pointer-events-none': value < 1,
      }"
      @click="change(-1)"
    >
      <img
        src="/icons/minus.svg"
        class="
        w-16 h-16 outline outline-8 outline-transparent transition-all
        group-hover:bg-brandLight group-hover:outline-brandLight"
        :class="{
          'opacity-50': value < 1,
        }"
        alt=""
      >
    </button>
    <div>
      <div
        v-if="loading && noInputField"
        class="miniNumber w-32 h-32 desk:h-40 flex items-center justify-center"
      >
        <LoadingIcon />
      </div>
      <div
        v-else-if="noInputField"
        class="miniNumber w-32 py-[10px] desk:py-12 h-32 desk:h-40 type-xs-medium desk:type-sm-medium"
      >
        {{ value }}
      </div>
      <input
        v-else
        :id="id"
        v-model="value"
        type="number"
        class="h-32 min-w-88 w-full px-8 fastOrder"
        :class="{ hideBrowserUpDownButtons: showButtonsOnDesktop }"
        min="1"
        :tabindex="(index * 10) +1"
        v-bind="attr"
        :disabled="loading"
        @keydown.enter="emit('enter')"
        @blur="emit('blurred')"
        @keyup="emit('change')"
        @change="emit('change')"
      >
    </div>
    <button
      class="w-32 h-32 desk:w-40 desk:h-40 flex items-center justify-center group"
      :class="{
        touchOnly: !showButtonsOnDesktop,
        disabled: loading,
      }"
      @click="change(1)"
    >
      <img
        src="/icons/plus.svg"
        class="
        w-16 h-16 outline outline-8 outline-transparent transition-all
        group-hover:bg-brandLight group-hover:outline-brandLight"
        alt=""
      >
    </button>
  </div>
</template>

<script setup lang="ts">
const value = defineModel<number>({ required: true });
const props = defineProps<{
  index: number,
  id: string,
  attr?: {
    [key: string]: string | number;
  };
  showButtonsOnDesktop?: boolean;
  noInputField?:boolean;
  loading?:boolean;
}>();

const emit = defineEmits<{
  (e: 'enter'): void;
  (e: 'blurred'): void;
  (e: 'change'): void;
}>();

const change = (dir: number) => {
  const newNumber = value.value + dir;
  if (typeof props.attr?.max === 'number' && newNumber > props.attr.max) {
    return;
  }

  const min = typeof props.attr?.min === 'number' ? props.attr.min : 1;
  if (newNumber >= min) {
    value.value = newNumber;
  }
  emit('change');
};

</script>

<style scoped lang="postcss">
.miniNumber {
  @apply leading-single rounded-0;
}

@media (pointer: coarse) { /*  touch */
  .miniNumber {

    @apply text-center;
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      @apply hidden;
    }
  }
}

@media (pointer: fine) {
  .miniNumber {
    @apply text-center;
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      @apply opacity-100;
    }
  }
  .touchOnly {
    @apply hidden;
  }
}

.hideBrowserUpDownButtons {
  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
    @apply opacity-100;
  }
}

.fastOrder {
  @apply leading-single rounded-0;
}

@media (pointer: coarse) { /*  touch */
  .fastOrder {
    @apply text-left;
  }
}

@media (pointer: fine) {
  .fastOrder {
    @apply text-left;
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      @apply opacity-100;
    }
  }
}

</style>
